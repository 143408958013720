import { useContext, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MicFill } from "react-bootstrap-icons";

import { DataContext } from "pages/Main";
import { useStory } from "hooks/useStory";
import Button from "react-bootstrap/Button";
import { getEdgeLeft, getRangeBoundingClientRect } from "./util";
import { AudioSnippet } from "../Operator/audio";

import type { Root, Audio } from "types";
import type { UsePopoverProps } from "hooks/usePopover";
import "./styles.scss";
import { findRootByUnitId } from "mockdata";

interface StoryBoardProps {
  root: Root;
  editable: boolean;
  setContentEditable: React.Dispatch<React.SetStateAction<boolean>>;
  operatorProps: UsePopoverProps;
  showForest: React.Dispatch<React.SetStateAction<boolean>>;
  setUserStoryBoard: React.Dispatch<React.SetStateAction<string>>;
}

export const StoryBoard = (props: StoryBoardProps): JSX.Element => {
  const { unitCtx, linkCtx, rootCtx, activeNodeCtx } = useContext(DataContext);
  const {
    root,
    editable,
    setContentEditable,
    operatorProps,
    showForest,
    setUserStoryBoard,
  } = props;
  const isActive = root.active;
  const unitList = unitCtx.get();
  const linkList = linkCtx.get();

  const relationUnitList = root.unitId
    ? unitList.filter((item) => {
        item.id === root.unitId;
      })
    : unitList;

  const [story] = useStory(root.content, relationUnitList, linkList);
  const [content, setContent] = useState(story);

  useEffect(() => {
    setContent(story);
  }, [relationUnitList, root, linkList]);

  const jumpToUnit = (linkElement: HTMLElement): void => {
    const unitId = linkElement.getAttribute("data-unit-id");
    const userId = linkElement.getAttribute("data-user-id");
    const data = findRootByUnitId(unitId!, userId!);

    showForest(false);
    setUserStoryBoard(userId ?? "");
    // Auto Jump
    rootCtx.active(data as Root);
    activeNodeCtx.set(data as Root);
  };

  const existedUnitOperator = (unitElement: HTMLElement): void => {
    const unitId = unitElement.id.replace("unit-", "");
    const top = unitElement.offsetTop - 35;
    const left = getEdgeLeft(unitElement.offsetLeft);
    operatorProps.onToggle({ open: true, position: { top, left } });
    const existedUnit = relationUnitList.filter(
      (item) => item.id === unitId,
    )[0];
    activeNodeCtx.set(existedUnit);
  };

  const newUnitOperator = (): void => {
    const selection = document.getSelection();
    if (selection?.rangeCount && !selection.isCollapsed) {
      const range = selection!.getRangeAt(0);
      const rect = getRangeBoundingClientRect(range);
      operatorProps.onToggle({ open: true, position: rect });
    } else {
      operatorProps.onToggle({ open: false });
    }
    activeNodeCtx.set(null);
  };

  const handleMouseUp = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    const target = event.target as HTMLElement;
    const isExistedUnit =
      target.tagName === "SPAN" && target.className.includes("unit");

    const isExistedLink =
      target.tagName === "SPAN" && target.className.includes("hyperlink");

    isExistedUnit ? existedUnitOperator(target) : newUnitOperator();
    isExistedLink && jumpToUnit(target);
  };

  const hidePopover = (): void => operatorProps.onToggle({ open: false });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setContent(event.target.value);

  const hasPermision = root.id !== "0";

  const createAudio = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    const audio = generateAudioData();
    if (audio) {
      const range = audio.range;
      const audioDOM = new DOMParser().parseFromString(
        renderToStaticMarkup(<AudioSnippet />),
        "text/html",
      );

      const selection = window.getSelection();
      range.collapse();
      selection?.removeAllRanges();
      selection?.addRange(range);
      range.insertNode(audioDOM.body.firstChild!);
    }
    setContentEditable(true);
  };

  const generateAudioData = (): Audio | null => {
    const contentEl = document.querySelector(".story-board > div");
    const selection = document.getSelection();

    if (selection?.rangeCount) {
      const range = selection.getRangeAt(0);
      const caretRange = range.cloneRange();
      caretRange.selectNodeContents(contentEl!);
      caretRange.setEnd(range.endContainer, range.endOffset);
      const caretOffset = caretRange.toString().length;

      return {
        id: `${Date.now()}`,
        start: caretOffset,
        end: caretOffset,
        range: caretRange,
      };
    }
    return null;
  };

  return (
    <>
      <div className={`button-container ${isActive ? "show" : "hide"}`}>
        <Button
          variant="primary"
          className="btn-add-audio"
          onClick={createAudio}>
          <MicFill />
          Add audio
        </Button>
      </div>
      <div
        className={`story-board ${isActive ? "show" : "hide"} ${
          hasPermision ? "editable" : ""
        }`}>
        <div
          contentEditable={editable}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          onMouseDown={hidePopover}
          onBlur={hidePopover}
          dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </>
  );
};
