import { createPortal } from "react-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { NodePlus, Diagram2 as Root, Trash, Link } from "react-bootstrap-icons";

import { DataContext } from "pages/Main";
import { useContext } from "react";
import { hasMaxRoots } from "mockdata";

import type { Unit, HyperLink } from "types";
import type { UsePopoverProps } from "hooks/usePopover";

import "./styles.scss";

export interface OperatorProps {
  operatorProps: UsePopoverProps;
  dialogProps: UsePopoverProps;
  linkDialogProps: UsePopoverProps;
  setContentEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

const generateUnitData = (): Unit | null => {
  const contentEl = document.querySelector(".story-board > div");
  const selection = document.getSelection();

  if (selection?.rangeCount && !selection.isCollapsed) {
    const range = selection.getRangeAt(0);
    const selectedLen = range.toString().length;

    const caretRange = range.cloneRange();
    caretRange.selectNodeContents(contentEl!);
    caretRange.setEnd(range.endContainer, range.endOffset);
    const caretOffset = caretRange.toString().length;

    return {
      id: `${Date.now()}`,
      start: caretOffset - selectedLen,
      end: caretOffset,
      hasRoot: false,
      content: range.toString(),
    };
  }
  return null;
};

const generateLinkData = (): HyperLink | null => {
  const unitData = generateUnitData();
  if (!unitData) {
    return null;
  }
  delete (unitData as Partial<Unit>).hasRoot;
  return { ...unitData, userId: "", unitId: "" };
};

export const Operator: React.FC<OperatorProps> = (props) => {
  const { unitCtx, activeNodeCtx } = useContext(DataContext);
  const {
    operatorProps,
    dialogProps,
    linkDialogProps,
    setContentEditable,
  } = props;

  const unit = activeNodeCtx.get() as Unit;
  const { top, left } = operatorProps.position;
  const isExistedUnit = !!unit;
  const hasRoot = isExistedUnit && unit!.hasRoot === true;
  const hasMaxRootsLimitation = hasMaxRoots(unit!);

  const afterOperation = (): void => {
    operatorProps.onToggle({ open: false });
    setContentEditable(true);
  };

  // TODO should re-order Unit List after Create Unit
  const createUnit = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    if (!isExistedUnit) {
      const newUnit = generateUnitData();
      unitCtx.add(newUnit);
    }
    afterOperation();
  };

  const createLink = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    linkDialogProps.onToggle({ open: true });
    const newLinkDataWithoutUnitId = generateLinkData();
    linkDialogProps.setExtraInfo(newLinkDataWithoutUnitId);
    afterOperation();
  };

  // TODO Mark As Root
  const markRoot = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.stopPropagation();
    if (isExistedUnit) {
      dialogProps.onToggle({ open: true });
    }
    afterOperation();
  };

  // Remove Unit (Could not be Root)
  const removeUnit = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    if (isExistedUnit && !unit!.hasRoot) {
      unitCtx.remove(unit!.id);
    }
    afterOperation();
  };

  // TODO Remove Root
  const removeRoot = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    if (isExistedUnit && !unit!.hasRoot) {
      // const newUnitList = removeUnitData(unit!.id);
      // setUnitDataList(newUnitList);
    }
    afterOperation();
  };

  const disableContentEdit = (): void => setContentEditable(false);

  return createPortal(
    <ButtonGroup
      size="sm"
      className={`story-popup ${operatorProps.show ? "show" : "hide"}`}
      style={{ top: `${top}px`, left: `${left}px` }}>
      <Button
        variant={!isExistedUnit ? "primary" : "secondary"}
        disabled={isExistedUnit}
        onClick={createUnit}
        onMouseEnter={disableContentEdit}>
        <NodePlus />
        Create Unit
      </Button>
      <Button
        className="btn-add-link"
        onClick={createLink}
        onMouseEnter={disableContentEdit}>
        <Link />
        Link to
      </Button>
      <Button
        variant={isExistedUnit ? "info" : "secondary"}
        disabled={!isExistedUnit || hasMaxRootsLimitation}
        onClick={markRoot}
        onMouseEnter={disableContentEdit}>
        <Root /> Create {hasRoot && !hasMaxRootsLimitation ? "another" : ""}{" "}
        Root
      </Button>
      <Button
        variant={isExistedUnit ? "warning" : "secondary"}
        disabled={!isExistedUnit}
        onClick={hasRoot ? removeRoot : removeUnit}
        onMouseEnter={disableContentEdit}>
        <Trash />
        Remove
      </Button>
    </ButtonGroup>,
    document.querySelector("#root-popover")!,
  );
};
