import Modal from "react-bootstrap/Modal";
import { UsePopoverProps } from "hooks/usePopover";
import { loadAllUserUnits } from "mockdata";
import { Link } from "react-bootstrap-icons";
import "./styles.scss";
import { DataContext } from "pages/Main";
import { useContext } from "react";

interface DialogProps {
  dialogProps: UsePopoverProps;
}

export const LinkDialog: React.FC<DialogProps> = (props) => {
  const { dialogProps } = props;
  const { linkCtx } = useContext(DataContext);

  const addLink = (userId: string, unitId: string): void => {
    const linkdata = {
      ...dialogProps.extraInfo,
      userId,
      unitId,
    };
    linkCtx.add(linkdata);
    dialogProps.onToggle({ open: false });
  };

  const allUnits = (): JSX.Element[] => {
    const unitList = loadAllUserUnits();
    return unitList.map((unit) => (
      <div
        key={`${unit.user}-${unit.id}`}
        className="user-link-item"
        onClick={(): void => addLink(unit.user, unit.id)}>
        <div className={unit.hasRoot ? `user-root-item` : `user-unit-item`}>
          <Link />
          <span>{unit.user}</span>
          <span>:</span>
          <span>{unit.content}</span>
        </div>
      </div>
    ));
  };

  const handleClose = (): void => dialogProps.onToggle({ open: false });

  return (
    <Modal centered show={dialogProps.show} onHide={handleClose}>
      <Modal.Body>
        <section>{allUnits()}</section>
      </Modal.Body>
    </Modal>
  );
};
