import { useState } from "react";
import type { FormControlProps } from "react-bootstrap";

type Value = "value";
type Checked = "checked";
type FieldValueName = Value | Checked;

type UseFieldHooksReturnType = {
  [key in FieldValueName]?: key extends Checked
    ? boolean
    : FormControlProps[Value];
} & {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const useInput = (
  initValue: FormControlProps["value"] | boolean,
  onChangeAction?: (event?: React.ChangeEvent<HTMLInputElement>) => void,
): UseFieldHooksReturnType => {
  const [value, setValue] = useState(initValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    onChangeAction?.(event);
  };

  return {
    value: `${value}`,
    onChange,
  };
};

export const useCheckbox = (
  initChecked: boolean,
  onChangeAction?: (event?: React.ChangeEvent<HTMLInputElement>) => void,
): UseFieldHooksReturnType => {
  const [checked, setChecked] = useState(initChecked);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(!checked);
    onChangeAction?.(event);
  };

  return {
    checked,
    onChange,
  };
};
