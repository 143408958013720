import { useContext } from "react";
import { useCheckbox, useInput } from "hooks/useForm";
import { DataContext } from "pages/Main";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import type { Root, Unit } from "types";
import "./styles.scss";

interface ModalFormProps {
  onSubmit?: (data: Root) => void;
  onCancel: () => void;
}

export const ModalForm: React.FC<ModalFormProps> = (props) => {
  const { onSubmit, onCancel } = props;
  const { rootCtx, activeNodeCtx } = useContext(DataContext);

  const unit = activeNodeCtx.get();
  const currentUserName = "User 1";
  const rootCode = `${Date.now()}`;
  const rootContent = unit?.content ?? "";
  const rootName = useInput(rootCode);
  const includeMaterial = useCheckbox(false);
  const importDeletion = useCheckbox(false);

  const handleConfirm = (): void => {
    const formData = {
      id: rootCode,
      name: rootName.value as string,
      unitId: unit!.id,
      content: rootContent,
      includeMaterial: includeMaterial.checked!,
      importDeletion: importDeletion.checked!,
      active: true,
    };
    onSubmit && onSubmit(formData);
    // TODO risk
    rootCtx.add(unit! as Unit, formData);
    activeNodeCtx.set({ ...unit!, hasRoot: true });
    onCancel();
  };

  const handleCancel = (): void => onCancel();

  return (
    <>
      <Form.Group controlId="root-code">
        <Form.Label>Root code</Form.Label>
        <Form.Control readOnly value={rootCode} />
      </Form.Group>
      <Form.Group controlId="root-name">
        <Form.Label>What would you like to name the root?</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{currentUserName}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control {...rootName} />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="root-content">
        <Form.Label>Create a root from the following material?</Form.Label>
        <Form.Control readOnly value={rootContent} />
      </Form.Group>
      <Form.Group controlId="include-content">
        <Form.Label>
          Do you want to include this material in the root?
        </Form.Label>
        <Form.Check
          type="switch"
          name="include-content"
          id="include-content"
          {...includeMaterial}
        />
      </Form.Group>
      <Form.Group controlId="import-deletion">
        <Form.Label>
          Do you want to import a root from the deletion repository?
        </Form.Label>
        <Form.Check
          type="switch"
          name="import-deletion"
          id="import-deletion"
          {...importDeletion}
        />
      </Form.Group>
      <section className="form-footer">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </section>
    </>
  );
};
