import { createContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { StoryBoard } from "components/StoryBoard";
import { TreeBoard } from "components/TreeBoard";
import { Operator } from "components/Operator";
import { loadData } from "mockdata";

import type { Root } from "types";

import "./styles.scss";
import { Dialog } from "components/Dialog";
import { usePopover } from "hooks/usePopover";
import {
  useActiveNode,
  UseActiveNodeReturnProps,
  useLinkList,
  UseLinkListReturnProps,
  useRootList,
  UseRootListReturnProps,
  useUnitList,
  UseUnitListReturnProps,
} from "hooks/useData";
import { EntranceBoard } from "components/TreeBoard/EntranceBoard";
import { LinkDialog } from "components/Dialog/LinkDialog";

const defaultOperatorPosition = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

type ContextType = {
  unitCtx: UseUnitListReturnProps;
  rootCtx: UseRootListReturnProps;
  linkCtx: UseLinkListReturnProps;
  activeNodeCtx: UseActiveNodeReturnProps;
};

export const DataContext = createContext({} as ContextType);

export const App = (): JSX.Element => {
  const currentUserId = `user${sessionStorage.tabID}`;
  const currentUserName = `User ${sessionStorage.tabID}`;
  const [editable, setContentEditable] = useState(true);
  const [showForest, setShowForest] = useState(true);
  const [userStoryBoard, setUserStoryBoard] = useState(currentUserId);
  const [boardType, setBoardType] = useState("Story Board");

  const activeNodeProps = useActiveNode();
  const linkListProps = useLinkList([]);
  const unitListProps = useUnitList([]);
  const rootListProps = useRootList([]);
  const operatorProps = usePopover({
    open: false,
    position: defaultOperatorPosition,
  });
  const dialogProps = usePopover({
    open: false,
  });
  const linkDialogProps = usePopover({
    open: false,
  });

  const hasPermission = userStoryBoard === `user${sessionStorage.tabID}`;

  const dataContextValue = {
    unitCtx: unitListProps,
    rootCtx: rootListProps,
    linkCtx: linkListProps,
    activeNodeCtx: activeNodeProps,
  };

  const backToForest = (): void => {
    setShowForest(true);
  };

  useEffect(() => {
    const { rootList, unitList, linkList } = loadData(userStoryBoard);
    unitListProps.set(unitList);
    rootListProps.set(rootList);
    linkListProps.set(linkList);
    const node = activeNodeProps.get();
    setBoardType(node?.id ? "Root Board" : "Story Board");
  }, [activeNodeProps.get(), userStoryBoard]);

  return (
    <DataContext.Provider value={dataContextValue}>
      <header>
        <h1>Manifesto of Dead Ends</h1>
        <span>
          <span className="userName">User:</span>
          <span>{currentUserName}</span>
        </span>
      </header>

      {showForest ? (
        <div className="entrance">
          <EntranceBoard
            showForest={setShowForest}
            setUserStoryBoard={setUserStoryBoard}
          />
        </div>
      ) : (
        <>
          <div className="user-story-tip">
            User {userStoryBoard.replace(/user/gi, "")}: {boardType}
          </div>
          <div className={`main ${hasPermission ? "" : "mask"}`}>
            <div className="btn-forest">
              <Button variant="warning" size="sm" onClick={backToForest}>
                Back to Forest
              </Button>
            </div>

            <TreeBoard userStoryBoard={userStoryBoard} />
            {rootListProps.get().map((root: Root) => (
              <StoryBoard
                key={root.id}
                root={root}
                editable={editable}
                setContentEditable={setContentEditable}
                operatorProps={operatorProps}
                showForest={setShowForest}
                setUserStoryBoard={setUserStoryBoard}
              />
            ))}
          </div>
          <Operator
            operatorProps={operatorProps}
            dialogProps={dialogProps}
            linkDialogProps={linkDialogProps}
            setContentEditable={setContentEditable}
          />
          <Dialog dialogProps={dialogProps} />
          <LinkDialog dialogProps={linkDialogProps} />
        </>
      )}
    </DataContext.Provider>
  );
};
