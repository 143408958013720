import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./pages/Main";
import { setupUserData } from "mockdata";

const iPageTabID = sessionStorage.tabID;
if (!iPageTabID) {
  const iLocalTabID = localStorage.tabID;
  const iPageTabID = !iLocalTabID ? 1 : Number(iLocalTabID) + 1;
  localStorage.tabID = iPageTabID;
  sessionStorage.tabID = iPageTabID;

  setupUserData(`user${iPageTabID}`);
}

const rootNode = document.getElementById("root");
ReactDOM.render(<App />, rootNode);
