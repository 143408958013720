import { useState } from "react";
import {
  activeRoot,
  addLinkData,
  addRootData,
  addUnitData,
  removeLinkData,
  removeUnitData,
  updateLinkData,
  updateUnitData,
} from "mockdata";
import type { HyperLink, Root, TreeNode, Unit } from "types";

export type UseUnitListReturnProps = ReturnType<typeof useUnitList>;
export type UseRootListReturnProps = ReturnType<typeof useRootList>;
export type UseLinkListReturnProps = ReturnType<typeof useLinkList>;
export type UseActiveNodeReturnProps = ReturnType<typeof useActiveNode>;

/* eslint-disable */
export const useActiveNode = (init?: TreeNode | null) => {
  const [node, setNode] = useState<TreeNode | null | undefined>(init);
  return {
    get: () => node,
    set: (value?: TreeNode | null): void => setNode(value),
  };
};

/* eslint-disable */
export const useUnitList = (init: Unit[]) => {
  const [unitDataList, setUnitDataList] = useState<Unit[]>(init);

  return {
    get: (): Unit[] => unitDataList,
    set: setUnitDataList,
    add: (unit?: Unit | null): void => {
      const rs = addUnitData(unit);
      setUnitDataList(rs);
    },
    update: (unit: Unit): void => {
      const rs = updateUnitData(unit);
      setUnitDataList(rs);
    },
    remove: (id: Unit["id"]): void => {
      const rs = removeUnitData(id);
      setUnitDataList(rs);
    },
  };
};

/* eslint-disable */
export const useRootList = (init: Root[]) => {
  const [rootDataList, setRootDataList] = useState<Root[]>(init);

  return {
    get: (): Root[] => rootDataList,
    set: setRootDataList,
    add: (unit: Unit, root: Root): void => {
      const rs = addRootData(unit, root);
      setRootDataList(rs);
    },
    active: (root?: Root): void => {
      const rs = activeRoot(root);
      setRootDataList(rs);
    },
    // update: (unit: Unit) => {
    //   const rs = updateUnitData(unit);
    //   setUnitDataList(rs);
    // },
    // remove: (id: Unit["id"]) => {
    //   const rs = removeUnitData(id);
    //   setUnitDataList(rs);
    // },
  };
};

/* eslint-disable */
export const useLinkList = (init: HyperLink[]) => {
  const [linkDataList, setLinkDataList] = useState<HyperLink[]>(init);

  return {
    get: (): HyperLink[] => linkDataList,
    set: setLinkDataList,
    add: (link?: HyperLink | null): void => {
      const rs = addLinkData(link);
      setLinkDataList(rs);
    },
    update: (link: HyperLink): void => {
      const rs = updateLinkData(link);
      setLinkDataList(rs);
    },
    remove: (id: HyperLink["id"]): void => {
      const rs = removeLinkData(id);
      setLinkDataList(rs);
    },
  };
};
