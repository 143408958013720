import { Position, Node, Edge, FlowElement } from "react-flow-renderer";
import type { Root, Unit } from "types";

const NODE_DISTANCE = 60;
const NODE_HEIGHT = 45;
const CONTROL_BAR_WIDTH = 60;
// TODO should calculate tree-board height
const containerHeight = 200;

const createElements = (data: Unit[], rootList: Root[]): Node[] => {
  const nodes = [] as Node[];

  data.forEach((item, index) => {
    const xAxis = CONTROL_BAR_WIDTH + index * NODE_DISTANCE;
    const yAxis = containerHeight! / 2 - NODE_HEIGHT;

    const classes = item.hasRoot ? "root-node" : "unit-node";

    const unitNode = {
      id: `node-${item.id}`,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      className: classes,
      data: { ...item, type: "unit" },
      position: { x: xAxis, y: yAxis },
    };
    nodes.push(unitNode);

    if (item.hasRoot) {
      const rootNodes = rootList.reduce((mem, root) => {
        const rootYAxis = mem.length === 0 ? yAxis - 40 : yAxis + 40;

        if (root.unitId === item.id) {
          const rootNode = {
            id: `root-${root.id}`,
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            className: `root-node ${root.active === true ? "active-node" : ""}`,
            data: { ...root, type: "root" },
            position: { x: xAxis + NODE_DISTANCE - 15, y: rootYAxis },
          };
          mem.push(rootNode);
        }
        return mem;
      }, [] as Node[]);
      nodes.push(...rootNodes);
    }
  });
  return nodes;
};

const createEdges = (data: Unit[], rootList: Root[]): Edge[] => {
  const edges = [] as Edge[];
  data.forEach((item, index, list) => {
    if (index > 0) {
      const prevItem = list[index - 1];
      const edge = {
        id: `edge-${prevItem.id}-${item.id}`,
        source: `node-${prevItem.id}`,
        target: `node-${item.id}`,
        type: "smoothstep",
        animated: true,
      };
      edges.push(edge);

      if (item.hasRoot) {
        const rootEdges = rootList.reduce((mem, root) => {
          if (root.unitId === item.id) {
            const edge = {
              id: `edge-${root.unitId}-${root.id}`,
              source: `node-${item.id}`,
              target: `root-${root.id}`,
              type: "smoothstep",
              animated: true,
            };
            mem.push(edge);
          }
          return mem;
        }, [] as Edge[]);
        edges.push(...rootEdges);
      }
    }
  });
  return edges;
};

export const createFlowElements = (
  unitList: Unit[],
  rootList: Root[],
): FlowElement[] => {
  return [
    ...createElements(unitList, rootList),
    ...createEdges(unitList, rootList),
  ];
};
