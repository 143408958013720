import { useState } from "react";

import type { Position } from "types";

interface InitProps {
  open: boolean;
  position?: Position;
}
type Action<U> = React.Dispatch<React.SetStateAction<U>>;

export interface UsePopoverProps {
  show: boolean;
  position: Position;
  onToggle: (props: InitProps) => void;
  onPosition: Action<Position>;
  /* eslint-disable */
  extraInfo: any;
  /* eslint-disable */
  setExtraInfo: React.Dispatch<React.SetStateAction<any>>;
}

export const usePopover = (props: InitProps): UsePopoverProps => {
  const { open: initOpenStatus, position: initPosition } = props;
  const [show, setShow] = useState(initOpenStatus);
  const [position, setPosition] = useState(initPosition ?? { top: 0, left: 0 });
  const [extraInfo, setExtraInfo] = useState(null);

  return {
    show,
    position,
    onToggle: (props: InitProps): void => {
      props.position && setPosition(props.position!);
      setShow(props.open);
    },
    onPosition: setPosition,
    extraInfo,
    setExtraInfo,
  };
};
