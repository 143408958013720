import { HyperLink, Root, Unit, UserUnit } from "types";
import data from "./data.json";

export type DATA_TYPE = typeof data & {
  userId?: string;
};

export const setupUserData = (userId: string): void => {
  if (!sessionStorage.getItem(userId)) {
    const userData = {
      ...JSON.parse(JSON.stringify(data)),
      userId,
    };
    sessionStorage.setItem(userId, JSON.stringify(userData));
    localStorage.setItem(userId, JSON.stringify(userData));
  }
};

export const loadData = (userId?: string): DATA_TYPE => {
  const activeUserId = userId ?? `user${sessionStorage.tabID}`;
  return JSON.parse(localStorage.getItem(activeUserId)!);
};

export const updateData = (
  rootListData?: Root[],
  unitListData?: Unit[],
  linkListData?: HyperLink[],
): void => {
  const currentUser = `user${sessionStorage.tabID}`;
  const { rootList, unitList, linkList } = loadData();
  rootListData = rootListData ?? rootList;
  unitListData = unitListData ?? unitList;
  linkListData = linkListData ?? linkList;

  const newData = JSON.stringify({
    rootList: rootListData,
    unitList: unitListData,
    linkList: linkListData,
    userId: currentUser,
  });
  sessionStorage.setItem(currentUser, newData);
  localStorage.setItem(currentUser, newData);
};

export const addUnitData = (unit?: Unit | null): Unit[] => {
  const saved = loadData().unitList;
  !!unit && saved.push(unit!);
  updateData(undefined, saved);
  return saved;
};

export const updateUnitData = (unit: Unit): Unit[] => {
  const saved: Unit[] = loadData().unitList;
  const updated = saved.map((item) => (item.id === unit.id ? unit : item));
  updateData(undefined, updated);
  return updated;
};

export const removeUnitData = (id: Unit["id"]): Unit[] => {
  const saved: Unit[] = loadData().unitList;
  const updated = saved
    .map((item) => item.id !== id && item)
    .filter((item) => !!item) as Unit[];
  updateData(undefined, updated);
  return updated;
};

export const addRootData = (unit: Unit, root: Root): Root[] => {
  const { rootList, unitList } = loadData();

  const hasMaxRoot =
    rootList.reduce((count: number, item: Root) => {
      if (item.unitId === unit.id) {
        count++;
      }
      return count;
    }, 0) >= 2;

  const newRootList = rootList.map((item) => {
    if (item.id != root.id) {
      item.active = false;
    }
    return item;
  });

  const updatedRootList = hasMaxRoot ? rootList : [...newRootList, root];

  const updatedUnitList = unitList.map((item) => {
    if (item.id === unit.id) item.hasRoot = true;
    return item;
  });

  updateData(updatedRootList, updatedUnitList);
  return updatedRootList;
};

export const activeRoot = (root?: Root): Root[] => {
  const { rootList } = loadData();

  const newList = rootList.map((item, index) => {
    if (root) {
      item.active = item.id == root!.id;
    } else if (index === 0) {
      item.active = true;
    } else {
      item.active = false;
    }
    return item;
  });
  updateData(newList);
  return newList;
};

export const hasMaxRoots = (unit?: Unit): boolean => {
  if (!unit || !unit.id) return false;

  const rootList = loadData().rootList;
  return rootList.filter((root) => root.unitId === unit.id).length >= 2;
};

export const loadAllUserUnits = (): UserUnit[] => {
  const userUnitList = Object.keys(localStorage)
    .filter((k) => k.indexOf("user") > -1)
    .flatMap((k) => {
      const { unitList } = loadData(k);
      return JSON.parse(JSON.stringify(unitList)).map((unit: Unit) => ({
        ...unit,
        user: k,
      }));
    });

  return userUnitList;
};

export const findRootByUnitId = (unitId: string, userId: string): Root => {
  const { rootList } = loadData(userId);
  const rs = rootList.filter((item) => item.unitId === unitId);
  if (rs.length > 0) {
    return rs[0];
  }
  return rootList[0];
};

export const addLinkData = (link?: HyperLink | null): HyperLink[] => {
  const saved = loadData().linkList as HyperLink[];
  !!link && saved.push(link!);
  updateData(undefined, undefined, saved);
  return saved;
};

export const updateLinkData = (unit: HyperLink): HyperLink[] => {
  const saved: HyperLink[] = loadData().linkList as HyperLink[];
  const updated = saved.map((item) => (item.id === unit.id ? unit : item));
  updateData(undefined, undefined, updated);
  return updated;
};

export const removeLinkData = (id: HyperLink["id"]): HyperLink[] => {
  const saved: HyperLink[] = loadData().linkList as HyperLink[];
  const updated = saved
    .map((item) => item.id !== id && item)
    .filter((item) => !!item) as HyperLink[];
  updateData(undefined, undefined, updated);
  return updated;
};
