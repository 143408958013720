import type { Unit as UnitType, HyperLink as LinkType } from "types";

import "./styles.scss";

export const Unit: React.FC<UnitType> = (props) => {
  const { id, hasRoot, children } = props;
  const classes = `unit ${hasRoot ? "root" : ""}`;

  return (
    <span className={classes} id={`unit-${id}`} data-unit-id={id}>
      {children}
    </span>
  );
};

export const HyperLink: React.FC<LinkType> = (props) => {
  const { id, userId, unitId, children } = props;

  return (
    <span
      className="hyperlink"
      id={`link-${id}`}
      data-user-id={userId}
      data-unit-id={unitId}>
      {children}
    </span>
  );
};
