import { useContext, useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Connection,
  Node,
  Edge,
  Elements,
  ReactFlowProvider,
} from "react-flow-renderer";
import { DataContext } from "pages/Main";
import { createFlowElements } from "./util";
import { loadData } from "mockdata";

import type { Root } from "types";
import "./styles.scss";

interface TreeBoardProps {
  userStoryBoard: string;
}
type ElementType = Root & {
  type: string;
};

export const TreeBoard: React.FC<TreeBoardProps> = (props) => {
  const { userStoryBoard } = props;
  const { unitCtx, rootCtx, activeNodeCtx } = useContext(DataContext);
  const initialElements = createFlowElements(unitCtx.get(), rootCtx.get());
  const [elements, setElements] = useState(initialElements);

  const onConnect = (params: Connection | Edge): Elements =>
    addEdge(params, elements);

  const onElementClick = (
    _: React.MouseEvent<Element, MouseEvent>,
    element: Node<ElementType> | Edge<ElementType>,
  ): void => {
    const data =
      element.data!.type === "unit"
        ? undefined
        : { ...element.data, active: true };
    rootCtx.active(data as Root);
    activeNodeCtx.set(data as Root);
  };

  useEffect(() => {
    const { rootList, unitList } = loadData(userStoryBoard);
    const elements = createFlowElements(unitList, rootList);
    setElements(elements);
  }, [unitCtx.get(), rootCtx.get(), userStoryBoard]);

  return (
    <div className="tree-board">
      <ReactFlowProvider>
        <ReactFlow
          onConnect={onConnect}
          onElementClick={onElementClick}
          elements={elements}
          selectNodesOnDrag={false}
          nodesDraggable={false}
          zoomOnScroll={false}
          panOnScroll={false}>
          <MiniMap />
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};
