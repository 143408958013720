import Modal from "react-bootstrap/Modal";
import { ModalForm } from "components/ModalForm";
import { UsePopoverProps } from "hooks/usePopover";

interface DialogProps {
  dialogProps: UsePopoverProps;
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const { dialogProps } = props;

  const handleClose = (): void => dialogProps.onToggle({ open: false });

  return (
    <Modal centered size="lg" show={dialogProps.show} onHide={handleClose}>
      <Modal.Body>
        <ModalForm onCancel={handleClose} />
      </Modal.Body>
    </Modal>
  );
};
