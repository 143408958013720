import { HyperLink as Link, Unit } from "components/Unit";
import { renderToStaticMarkup } from "react-dom/server";
import type { HyperLink, Unit as UnitType } from "types";

type ReturnFunc = typeof setStory;

const renderUnit = (story: string, data: UnitType | HyperLink): string => {
  const { start, end } = data;

  const content = (): string => {
    if ("userId" in data && "unitId" in data) {
      if (data.userId && data.unitId) {
        return renderToStaticMarkup(
          <Link {...data}>{story.slice(start, end)}</Link>,
        );
      }
    } else {
      return renderToStaticMarkup(
        <Unit {...data}>{story.slice(start, end)}</Unit>,
      );
    }
    return "";
  };

  const selectContent = content();
  story = `${story.slice(0, start)}${selectContent}${story.slice(end)}`;
  return story;
};

const setStory = (
  story: string,
  units: UnitType[],
  links: HyperLink[],
): string => {
  const list = [...units, ...links].sort((prev, cur) =>
    Number(prev.start) - Number(cur.start) > 0 ? 1 : -1,
  );
  const len = list.length;
  for (let i = len - 1; i >= 0; i--) {
    story = renderUnit(story, list[i]);
  }
  return story;
};

export const useStory = (
  story: string,
  units: UnitType[],
  links: HyperLink[],
): [string, ReturnFunc] => {
  story = setStory(story, units, links);
  return [story, setStory];
};
