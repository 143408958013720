import ReactFlow, {
  addEdge,
  Connection,
  Edge,
  Elements,
  FlowElement,
  ReactFlowProvider,
} from "react-flow-renderer";
import { PersonCircle } from "react-bootstrap-icons";
import { createFlowElements } from "./util";
import { DATA_TYPE } from "mockdata";
import "./styles.scss";

interface Props {
  showForest: React.Dispatch<React.SetStateAction<boolean>>;
  setUserStoryBoard: React.Dispatch<React.SetStateAction<string>>;
}

export const EntranceBoard: React.FC<Props> = (props) => {
  const { showForest, setUserStoryBoard } = props;
  const userCount = Number(localStorage.tabID);
  const userDataList = [...Array(userCount).keys()].map(
    (idx) => JSON.parse(localStorage.getItem(`user${idx + 1}`)!) as DATA_TYPE,
  );

  const getElements = (userData: DATA_TYPE): FlowElement[] =>
    createFlowElements(userData.unitList, userData.rootList);

  const onConnect = (
    userData: DATA_TYPE,
  ): ((params: Connection | Edge) => Elements) => {
    const elements = getElements(userData);
    return (params: Connection | Edge): Elements => addEdge(params, elements);
  };

  const showStoryBoard = (userId?: string): void => {
    showForest(false);
    setUserStoryBoard(userId ?? "");
  };

  return (
    <div className="entrance-board">
      {userDataList.map((userData) => (
        <div
          className="user-data-row"
          key={userData.userId}
          onClick={(): void => showStoryBoard(userData.userId)}>
          <div className="user-avatar">
            <span>
              <PersonCircle color="#e1813e" size="24" />
            </span>
            <span>{userData.userId}</span>
          </div>
          <div className="user-flow">
            <ReactFlowProvider>
              <ReactFlow
                onConnect={onConnect(userData)}
                elements={getElements(userData)}
                selectNodesOnDrag={false}
                nodesDraggable={false}
                zoomOnScroll={false}
                panOnScroll={false}
              />
            </ReactFlowProvider>
          </div>
        </div>
      ))}
    </div>
  );
};
