import type { Position } from "types";

const EDITOR_PADDING = 32;
const OPERATOR_WIDTH = 350;

export const getEdgeLeft = (calulatedLeft: number): number => {
  const screenWidth = screen.width;
  if (screenWidth - calulatedLeft < OPERATOR_WIDTH) {
    return screenWidth - OPERATOR_WIDTH - EDITOR_PADDING;
  } else {
    return calulatedLeft;
  }
};

export const getRangeBoundingClientRect = (range: Range): Position => {
  const rects = range.getClientRects();
  const POPOVER_HEIGHT = 35;
  let top = 0;
  let right = 0;
  let bottom = 0;
  let left = 0;
  let width = 0;
  let height = 0;

  if (rects.length > 0) {
    ({ top, right, bottom, left, width, height } = rects[0]);
  }

  return {
    top: top - POPOVER_HEIGHT + window.scrollY,
    right,
    bottom,
    left: getEdgeLeft(left),
    width,
    height,
  };
};
